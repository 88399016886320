import React, { useState } from 'react'
import './board.css'
import Notes from '../notwriter/Notes'
import ypin from '../../assets/thumb.png'
import Modal from '../modals/Modal'


const Noteboard = () => {

  const [show, setShow] = useState(false)
  const [delMsg, setDelmsg] = useState('')
  const [delmodel, setDelmodel] = useState(false)
  const [delid, setDelid] = useState(null)
  const [notelist, setNotelist] = useState(
    JSON.parse(window.localStorage.getItem('notes'))
  )
  const MSG = ['Are you sure you want to delete ?',
    `Do you want to delete All ${notelist.length} Notes ?`
  ]
  function makeCaps(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const toggleWrite = () => {
    setShow(prev => !prev)
  }

  const handleDelete = (ID) => {
    setDelmsg(MSG[0])
    setDelid(ID)
    setDelmodel(true)


  }
  const handleYesNo = (res) => {
    if (res === 0) {
      setDelmodel(false)
      return
    }
    if (res === 1 && delid === null) {
      setNotelist([])
    } else {
      setNotelist(prev => prev.filter((note) => note.id !== delid))
    }
    setDelmodel(false)
  }

  const handleDeleteAll = () => {
    setDelmsg(MSG[1])
    setDelmodel(true)
    setDelid(null)

  }

  window.localStorage.setItem('notes', JSON.stringify(notelist))


  return (
    <div className='board-wraper'>
      <nav className="navbar">
        <div className="create-wraper">
          <button className='create'
            onClick={toggleWrite}>
            <span className='counts'>{notelist.length} </span>
            Create Note
            <span className='arrows'>&#8594;</span></button>
        </div>

        <h1 className='main-title'>Sticky Notes</h1>
        <button className="del-all" onClick={handleDeleteAll}>
          Delete all <span className='del-count'> {notelist.length} </span> Notes
        </button>
      </nav>
      <div className="note-wraper">
        {notelist.map((note) => (
          <div className="notes" key={note.id}
            style={{ margin: note.margin, backgroundColor: note.color, transform: note.rotate }}          >
            <h2 className='title'>{makeCaps(note.title)}</h2>
            <hr />
            <p>{note.note} </p>
            <button className="deleter" onClick={() => handleDelete(note.id)}>
              Delete this Note
            </button>
            <img className='boardpin' src={ypin} alt="pin" style={note.pinpos} />
          </div>

        ))}
      </div>

      {show && <Notes toggleWrite={toggleWrite} notelist={notelist} setNotelist={setNotelist} />}

      {delmodel && <Modal handleYesNo={handleYesNo} delMsg={delMsg} />}
    </div>
  )
}

export default Noteboard