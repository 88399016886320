import React, { useRef, useState } from 'react'
import { colors, margins, rotations, pinKey } from '../datafunc'
import './notes.css'

const Notes = ({ toggleWrite, setNotelist, notelist }) => {
  const initValue = { title: '', note: '' }
  const [values, setValues] = useState(initValue)
  const titleRef = useRef(null)

  const handlePost = (e) => {
    e.preventDefault()

    if (!values.title || !values.note) return
    let ID = new Date().getTime()
    let rotates = rotations()
    let newnote = {
      ...values, id: ID, color: colors(), margin: margins(),
      rotate: `rotate(${rotates})`, pinpos: pinKey[rotates]
    }

    setNotelist(prev => ([...prev, newnote]))
    setValues(initValue)
    titleRef.current.focus()

  }
  return (
    <div className='newnote-wraper'>
      <div className="formWraper">
        <form className="new-note" onSubmit={handlePost}>
          <div className="note-title">
            <input type="text" placeholder='Note title . . .' name='title' value={values.title} autoFocus ref={titleRef}
              onChange={(e) => setValues(prev => ({ ...prev, title: e.target.value }))} />
            <textarea name="note" id="notbody" placeholder='Write your Note . . . '
              onChange={(e) => setValues(prev => ({ ...prev, note: e.target.value }))} value={values.note}></textarea>
          </div>

          <button type='submit' className="post btn" >Post</button>

        </form>
        <button type='button' className="closebtn btn"
          onClick={toggleWrite}>X</button>
      </div>

    </div >
  )
}

export default Notes