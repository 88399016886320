import React from 'react'
import './modal.css'

const Modal = ({ handleYesNo, delMsg }) => {
  return (
    <div className='modals'>
      <div className="modal-inner">
        <h2>Delete Comfirmation</h2>
        <hr />
        <h3>{delMsg} </h3>
        <hr />
        <div className="yesnobtn">
          <button className="nobtn" onClick={() => handleYesNo(0)}>No</button>
          <button className="yesbtn" onClick={() => handleYesNo(1)}>Yes</button>
        </div>
      </div>

    </div>
  )
}

export default Modal