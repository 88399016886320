import React from 'react'
import Noteboard from './components/noteboard/Noteboard'

const App = () => {
  return (
    <div className='app'>
      <Noteboard />

    </div>
  )
}

export default App