function colors(idx) {
  let colorList = ['#c2ff3d', '#ff3de8', '#3dc2ff', '#04e022', '#bc83e6', '#ebb328', '#d9b8cc', '#7afcff', '#fff740', '#afbccf', '#85e0a3', '#ffaf00', '#04e040']
  let eachColor = Math.floor(Math.random() * colorList.length)
  return colorList[eachColor]
}

function rotations() {
  let rotatelist = ['9deg', '6deg', '2deg', '-2deg', '-6deg', '-9deg']
  let Rand = Math.floor(Math.random() * rotatelist.length)
  return rotatelist[Rand]
}

function margins() {
  let marginList = ['-5px', '3px', '8px', '1px', '12px', '16px']
  let randNum = Math.floor(Math.random() * marginList.length)
  return marginList[randNum]
}
let pinKey = {
  '9deg': { left: '0.4rem' }, '6deg': { left: '3.5rem' }, '2deg': { left: '5.5' },
  '-2deg': { right: '5.5rem' }, '-6deg': { right: '3.5rem' }, '-9deg': { right: '-0.8rem' }
}



export { colors, rotations, margins, pinKey }

